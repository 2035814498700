























import { User } from '@/entities/user/User'
import { trsl } from '@/i18n/lang'
import { clone } from 'lodash'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class UserSelectionList extends Vue {
    @Prop() value!: number[]
    @Prop() users!: User[]
    @Prop() height?: string

    toggleSelection(id: number) {
        const newValue = clone(this.value)
        const index = newValue.findIndex(value => value == id)
        if (index == -1) newValue.push(id)
        else newValue.splice(index, 1)
        this.$emit('input', newValue)
    }

    isSelected(id: number) {
        return this.value.includes(id)
    }

    //Table API
    rowClicked(item: { id: number }) {
        this.toggleSelection(item.id)
    }

    rowClass(item: { id: number }) {
        return 'clickable' + (this.isSelected(item.id) ? ' table-row-selected' : '')
    }

    fields = [
        {
            key: 'id',
            label: trsl('input.property.id'),
            sortable: true,
        },
        {
            key: 'name',
            label: trsl('input.property.name'),
            sortable: true,
        },
        {
            key: 'selected',
            label: '',
        },
    ]

    get displayUsers() {
        return this.users.map(user => {
            return {
                id: user.id,
                name: `${user.firstname} ${user.lastname}`,
            }
        })
    }
}
