











































import { Route } from '@/router'
import { Vue, Component } from 'vue-property-decorator'
import UserSelectionModal from '@/components/user/UserSelectionModal.vue'
import store from '@/store'
import UserList from '@/components/user/UserList.vue'
import EventProperties from '@/components/event/EventProperties.vue'
import { createEmptyEvent } from '@/entities/mappers/Factory'

@Component({
    components: {
        UserSelectionModal,
        UserList,
        EventProperties,
    },
})
export default class EventDetailsView extends Vue {
    Route = Route

    mounted() {
        store.dispatch.event.fetchEvents()
        store.dispatch.user.fetchUsers()
    }

    get eventId() {
        return parseInt(this.$route.params['eventId'])
    }

    get event() {
        return store.getters.event.eventWithId(this.eventId) || createEmptyEvent()
    }

    get assignedUsers() {
        return store.getters.user.usersAssignedToEventId(this.eventId)
    }

    get assignedUserIds() {
        return this.assignedUsers.map(user => user.id)
    }

    get allUsers() {
        return store.getters.user.users
    }

    remove(userId: number) {
        store.dispatch.message.showDialog({ textKey: 'remove-user', confirmKey: 'unassign' }).then(confirmed => {
            if (confirmed) {
                store.dispatch.event.removeUser({ eventId: this.eventId, userId: userId }).then(() => {
                    store.commit.message.showToast({ textKey: 'success.manage-event-users', variant: 'success' })
                })
            }
        })
    }

    submitUserSelection(ids: number[]) {
        store.dispatch.event.manageUsers({ eventId: this.eventId, newUserIds: ids }).then(() => {
            store.commit.message.showToast({ textKey: 'success.manage-event-users', variant: 'success' })
        })
    }

    showUserSelectionModal = false
    manageUsers() {
        this.showUserSelectionModal = true
    }
}
