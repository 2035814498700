








































import { Vue, Component, Prop } from 'vue-property-decorator'
import { User } from '@/entities/user/User'
import { trsl } from '@/i18n/lang'
import JudgeLockedBadge from '@/components/judge/JudgeLockedBadge.vue'
import UserRoleBadge from '@/components/user/UserRoleBadge.vue'

@Component({
    components: {
        JudgeLockedBadge,
        UserRoleBadge,
    },
})
export default class UserList extends Vue {
    @Prop() users!: User[]
    @Prop() height?: string
    @Prop() actionIcon?: string
    @Prop() showProperties?: string[]

    click(id: number) {
        this.$emit('select', id)
    }

    allFields = [
        {
            key: 'id',
            label: trsl('input.property.id'),
            sortable: true,
        },
        {
            key: 'name',
            label: trsl('input.property.name'),
            sortable: true,
        },
        {
            key: 'role',
            label: trsl('user.property.role'),
            sortable: true,
        },
        {
            key: 'username',
            label: trsl('input.property.username'),
        },

        {
            key: 'email',
            label: trsl('input.property.email'),
        },
        {
            key: 'judge',
            label: trsl('user.property.judge-id-short'),
            sortable: true,
        },
        {
            key: 'events',
            label: trsl('user.property.events'),
            sortable: true,
        },
        {
            key: 'action',
            label: '',
            tdClass: 'width-sm',
        },
    ]

    get fields() {
        if (!this.showProperties) return this.allFields
        const fields = []
        for (const key of this.showProperties) {
            fields.push(...this.allFields.filter(field => field.key == key))
        }
        return fields
    }

    get displayUsers() {
        return this.users.map(user => {
            return {
                id: user.id,
                name: `${user.firstname} ${user.lastname}`,
                username: user.username,
                email: user.email,
                role: user.role,
                judge: user.judge,
                events: user.events.length,
            }
        })
    }
}
