








import { UserRole } from '@/entities/user/User'
import { trsl } from '@/i18n/lang'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class UserRoleBadge extends Vue {
    @Prop() role!: UserRole

    get name() {
        return trsl(`user.role.${this.role}`)
    }
}
