














import { User } from '@/entities/user/User'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import UserSelectionList from './UserSelectionList.vue'

@Component({
    components: {
        UserSelectionList,
    },
})
export default class UserSelectionModal extends Vue {
    @Prop() value!: boolean
    @Prop() users!: User[]
    @Prop() selectedIds!: number[]

    editSelection: number[] = []

    @Watch('show')
    overrideSelection() {
        if (this.show) {
            this.editSelection = this.selectedIds
        }
    }

    get show() {
        return this.value
    }

    set show(newValue: boolean) {
        this.$emit('input', newValue)
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    preventHide(event: any) {
        if (event.trigger == 'backdrop') event.preventDefault()
    }

    submit() {
        this.$emit('submit', this.editSelection)
    }
}
