











import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class JudgeLockedBadge extends Vue {
    @Prop() locked?: boolean
}
