

























import { Event } from '@/entities/event/Event'
import { createEmptyEvent } from '@/entities/mappers/Factory'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class EventProperties extends Vue {
    @Prop() model?: Event
    @Prop() showId?: boolean
    @Prop() showName?: boolean

    get event(): Event {
        return this.model || createEmptyEvent()
    }
}
